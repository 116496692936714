import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceAPI } from "src/api";
import { IConsolidatedDeviceViewReturn } from "src/api/devices";
import { Card } from "src/components/structure";
import DatePicker from "src/components/structure/DatePicker";
import Table from "src/components/structure/Table";
import * as UserActions from "src/reducers/userReducer";


interface IConsolidatedDeviceViewTabProps {
  loading: boolean;
  userId: number;
  deviceId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IConsolidatedDeviceViewTabState {
  loading: boolean;
  showRawModal: boolean;
  selectedRawData: any;
  allRows: IConsolidatedRow[];
  filteredRows: IConsolidatedRow[];
  csvData: any[];
  start: moment.Moment;
  end: moment.Moment;
  showLocations: boolean;
  showActions: boolean;
  showEvents: boolean;
  showBars: boolean;
  showConnectivityLogs: boolean;
}

interface IConsolidatedRow {
  timestamp: string;
  rowType: "Action" | "Connectivity Log" | "Event" | "Location" | "Status Bar"
  rawData: any;
  stringedData: string;
  viewRaw: any;
}

const cols: any = [{
  label: "Time",
  field: "timestamp",
},{
  label: "Type",
  field: "rowType",
},{
  label: "Summary",
  field: "stringedData",
},{
  label: "Raw",
  field: "viewRaw",
},];
const timeFormat = "MM/DD/YY HH:mm:ss";

class ConsolidatedDeviceViewTab extends Component<IConsolidatedDeviceViewTabProps, IConsolidatedDeviceViewTabState> {
  constructor(props: IConsolidatedDeviceViewTabProps) {
    super(props);

    this.state = {
      loading: false,
      showRawModal: false,
      selectedRawData: {},
      allRows: [],
      filteredRows: [],
      csvData: [],
      start: moment().subtract(14, "days"),
      end: moment(),
      showLocations: true,
      showActions: true,
      showEvents: true,
      showBars: true,
      showConnectivityLogs: true,
    };

    this.updateFilterCheckbox = this.updateFilterCheckbox.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.showRawModal = this.showRawModal.bind(this);
    this.hideRawModal = this.hideRawModal.bind(this);
    this.getData = this.getData.bind(this);
    this.filterRows = this.filterRows.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-2">
            <Card title="Filters">
              <div className="row">
                <div className="col-12">
                  <label>Start</label>
                  <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>End</label>
                  <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label>Show:</label>
                  <ul style={{listStyleType: "none"}}>
                    <li><input type="checkbox" id="showActions" checked={this.state.showActions} onChange={this.updateFilterCheckbox} /> Actions</li>
                    <li><input type="checkbox" id="showConnectivityLogs" checked={this.state.showConnectivityLogs} onChange={this.updateFilterCheckbox} /> Connectivity</li>
                    <li><input type="checkbox" id="showEvents" checked={this.state.showEvents} onChange={this.updateFilterCheckbox} /> Events</li>
                    <li><input type="checkbox" id="showLocations" checked={this.state.showLocations} onChange={this.updateFilterCheckbox} /> Locations</li>
                    <li><input type="checkbox" id="showBars" checked={this.state.showBars} onChange={this.updateFilterCheckbox} /> Status Bars</li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-success" onClick={this.getData}>Apply</button>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-lg-10">
            <Table
              title="Consolidated History"
              loading={this.props.loading || this.state.loading}
              showDownload={true}
              csvData={this.state.csvData}
              csvFileName={`consolidated-deviceId-${this.props.deviceId}`}
              columns={cols}
              rows={this.state.filteredRows}
            />
            <Modal
              show={this.state.showRawModal}
              onHide={this.hideRawModal}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Raw Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <pre>{JSON.stringify(this.state.selectedRawData, null, 2)}</pre>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={this.hideRawModal}>Close</button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  private async getData(){
    const csvData: any[] = [];
    this.setState({ loading: true }, async () => {
      try{
        const options = {
          start: this.state.start.format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.end.format("YYYY-MM-DDTHH:mm:ss") + "Z",
        }
        const res = await DeviceAPI.getConsolidatedDeviceData("wagz2", this.props.userId, this.props.deviceId, options);
        const result: IConsolidatedDeviceViewReturn = res.body.data;

        // we need to loop over this and put it into a common format for the table
        const rows: IConsolidatedRow[] = [];
        if(result.actions){
          for(const a of result.actions){
            rows.push({
              timestamp: moment(a.startTime).utc().format(timeFormat),
              rowType: "Action",
              rawData: a,
              stringedData: `${a.action} - ${a.status}`,
              viewRaw: (<button className="btn btn-block btn-primary" onClick={() => this.showRawModal(a)}>View</button>)
            })
          }
        }
        if(result.events){
          for(const a of result.events){
            rows.push({
              timestamp: moment(a.time).utc().format(timeFormat),
              rowType: "Event",
              rawData: a,
              stringedData: `${a.domain} - ${a.message}`,
              viewRaw: (<button className="btn btn-block btn-primary" onClick={() => this.showRawModal(a)}>View</button>)
            })
          }
        }
        if(result.connectivityLogs){
          for(const a of result.connectivityLogs){
            rows.push({
              timestamp: moment(a.posted).utc().format(timeFormat),
              rowType: "Connectivity Log",
              rawData: a,
              stringedData: `Cell Mode: ${a.cellMode} - Sys: ${a.sysmode} - Reg Status: ${a.registrationStatus}`,
              viewRaw: (<button className="btn btn-block btn-primary" onClick={() => this.showRawModal(a)}>View</button>)
            })
          }
        }
        if(result.locations){
          for(const a of result.locations){
            rows.push({
              timestamp: moment(a.posted).utc().format(timeFormat),
              rowType: "Location",
              rawData: a,
              stringedData: `${a.lat}, ${a.lng} from ${a.source} - Accuracy: ${a.locationAccuracy}`,
              viewRaw: (<button className="btn btn-block btn-primary" onClick={() => this.showRawModal(a)}>View</button>)
            })
          }
        }
        if(result.statusBars){
          for(const a of result.statusBars){
            rows.push({
              timestamp: moment(a.inserted).utc().format(timeFormat),
              rowType: "Status Bar",
              rawData: a,
              stringedData: `Bluetooth: ${a.bluetooth} - Battery: ${a.batteryLevel} - Cell: ${a.cellLevel} - Wifi: ${a.wifiLevel} - GPS: ${a.rawLocationAccuracy}`,
              viewRaw: (<button className="btn btn-block btn-primary" onClick={() => this.showRawModal(a)}>View</button>)
            })
          }
        }

        // the current table component doesn't seem to allow passing in the 
        // order prop from the docs, so we will just reverse sort by date
        // by hand here until we can update the component
        const sortedRows = rows.sort((a, b) => {
          return moment(a.timestamp, timeFormat).isBefore(moment(b.timestamp, timeFormat)) ? 1 : -1;
        })
        for(const row of sortedRows) {
          const d = {
            timestamp: moment(row.timestamp, "").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
            type: row.rowType,
            display: row.stringedData.replaceAll(","," "),
            raw: JSON.stringify(row.rawData).replaceAll(",","-"),
          }
          csvData.push(d);
        }
        this.setState({ loading: false, allRows: sortedRows, csvData }, () => {this.filterRows();});
      }catch(err){
        this.setState({loading: false});
      }
    })
  }

  private filterRows(){
    const filteredRows: IConsolidatedRow[] = [];
    // we need to check each entry against the allowed filters
    for(const r of this.state.allRows){
      if(r.rowType === "Action" && this.state.showActions){
        filteredRows.push(r);
        continue;
      }
      if(r.rowType === "Connectivity Log" && this.state.showConnectivityLogs){
        filteredRows.push(r);
        continue;
      }
      if(r.rowType === "Event" && this.state.showEvents){
        filteredRows.push(r);
        continue;
      }
      if(r.rowType === "Location" && this.state.showLocations){
        filteredRows.push(r);
        continue;
      }
      if(r.rowType === "Status Bar" && this.state.showBars){
        filteredRows.push(r);
        continue;
      }
    }

    this.setState({ loading: false, filteredRows })
  }

  private showRawModal(e: any){
    this.setState({ showRawModal: true, selectedRawData: e});
  }

  private hideRawModal(){
    this.setState({ showRawModal: false, selectedRawData: {}});
  }


  private updateFilterCheckbox(e: any) {
    const id = e.target.id;
    let ns: any = this.state;
    ns[id] = !ns[id];
    this.setState(ns, () => this.filterRows());
  }

  private updateStart(newTime: moment.Moment){
    this.setState({ start: newTime });
  }

  private updateEnd(newTime: moment.Moment){
    this.setState({ end: newTime });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidatedDeviceViewTab);
