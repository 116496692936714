export const roles = [
  { value: "support", label: "Support" },
  { value: "product", label: "Product" },
  { value: "backend", label: "Backend" },
  { value: "administrator", label: "Admin" },
  { value: "reporting", label: "Reporting" },
  { value: "qa", label: "Quality Assurance" },
  { value: "mobile", label: "Mobile Engineering" },
  { value: "firmware", label: "Firmware Engineering" },
];

export const parseUserRoles = (input: string[]): any[] => {
  const retRoles: any[] = [];
  for (const r of roles) {
    for (const i of input) {
      if (i === r.value) {
        retRoles.push(r);
      }
    }
  }
  return retRoles;
};

// I know it would be easier to get this list from the server, but for now I want to manually limit which known events
// are shown and which are not, since Android crashes if it gets an event it's not prepared for (yes, even "Known" events)
export const events = [
  { value: "activation_error", label: "Activation Error" },
  // { value: "allowed_dog_detected", label: "Allowed Dog Detected" },
  { value: "bark", label: "Bark" },
  { value: "billing_payment_error", label: "Billing Payment Error" },
  { value: "brown_out", label: "Brown Out" },
  // { value: "calibrate_feeder", label: "Calibrate Feeder" },
  { value: "collar_battery_needs_charging", label: "Collar Battery Needs Charging" },
  { value: "collar_battery_too_weak", label: "Collar Battery Too Weak" },
  { value: "collar_fully_charged", label: "Collar Fully Charged" },
  { value: "collar_low_battery", label: "Collar Low Battery" },
  { value: "collar_okay_battery", label: "Collar Okay Battery" },
  { value: "collar_turned_on", label: "Collar Turned On" },
  { value: "correction_manual", label: "Correction Manual" },
  { value: "crash", label: "Crash" },
  { value: "device_power_off", label: "Device Power Off" },
  { value: "device_power_on", label: "Device Power On" },
  // { value: "dog_ate", label: "Dog Ate" },
  // { value: "dog_ate_some", label: "Dog Ate Some" },
  // { value: "dog_missed_meal", label: "Dog Missed Meal" },
  { value: "excess_barking", label: "Excess Barking" },
  { value: "exercise_time_too_high", label: "Exercise Time Too High" },
  { value: "exercise_time_too_low", label: "Exercise Time Too Low" },
  // { value: "feeder_foreign_object", label: "Feeder Foreign Object" },
  // { value: "feeder_low_battery", label: "Feeder Low Battery" },
  // { value: "feeder_malfunction", label: "Feeder Malfunction" },
  // { value: "feeder_meal_dispensed", label: "Feeder Meal Dispensed" },
  // { value: "feeder_obstruction", label: "Feeder Obstruction" },
  // { value: "fence_cross_in", label: "Fence Cross: In" },
  // { value: "fence_cross_out", label: "Fence Cross: Out" },
  // { value: "forbidden_dog_detected", label: "Forbidden Dog Detected" },
  { value: "geofence_enter_allowed", label: "Geofence Enter: Allowed" },
  { value: "geofence_enter_allowed_cat", label: "Geofence Enter: Allowed (Cat)" },
  { value: "geofence_enter_forbidden", label: "Geofence Enter: Forbidden" },
  { value: "geofence_exit_allowed", label: "Geofence Exit: Allowed" },
  { value: "geofence_exit_allowed_cat", label: "Geofence Exit: Allowed (Cat)" },
  { value: "geofence_exit_forbidden", label: "Geofence Exit: Forbidden" },
  { value: "healthbox_low_trays", label: "Health Box Low Trays" },
  { value: "healthbox_tray_change_complete", label: "Health Box Change Tray Completed" },
  { value: "healthbox_tray_change_now", label: "Health Box Change Tray Now" },
  { value: "healthbox_tray_change_reminder", label: "Health Box Change Tray Reminder" },
  { value: "healthbox_tray_change_soon", label: "Health Box Change Tray Soon" },
  { value: "healthbox_update_finished", label: "Health Box Software Update Finished" },
  { value: "healthbox_update_started", label: "Health Box Software Update Started" },
  { value: "healthbox_visit_known", label: "Health Box Visit: Pet Known" },
  { value: "healthbox_visit_unknown", label: "Health Box Visit: Pet Unknown" },
  { value: "lights_off", label: "Lights Off" },
  { value: "lights_on", label: "Lights On" },
  // { value: "low_food", label: "Low Food" },
  { value: "low_power_disabled", label: "Low Power Disabled" },
  { value: "low_power_enabled", label: "Low Power Enabled" },
  // { value: "new_food_recommendation", label: "New Food Recommendation" },
  { value: "ota_finished", label: "OTA Finished" },
  { value: "ota_started", label: "OTA Started" },
  // { value: "pet_door_enter", label: "Pet Door: Enter" },
  // { value: "pet_door_exit", label: "Pet Door: Exit" },
  // { value: "pet_door_motion_detected", label: "Pet Door: Motion Detected" },
  // { value: "shield_alert", label: "Shield Alert" },
  // { value: "shield_notification", label: "Shield Notification" },
  { value: "shield_recovery", label: "Shield Recovery" },
  { value: "shield_violation", label: "Shield Violation" },
  { value: "sleep_time_too_high", label: "Sleep Time Too High" },
  { value: "sleep_time_too_low", label: "Sleep Time Too Low" },
  // { value: "talk_end", label: "Talk End" },
  // { value: "talk_start", label: "Talk Start" },
  { value: "temp_returned_to_safe_range", label: "Temperature: Safe" },
  { value: "temp_too_high", label: "Temp Too High" },
  { value: "temp_too_low", label: "Temp Too Low" },
  { value: "user_setup_error", label: "User Setup Error" },
  { value: "user_subscription", label: "User Subscription" },
  { value: "walk_completed", label: "Walk Completed" },
  // { value: "whello_inserted", label: "Whello Inserted" },
];

export const mobileAppPlatform = [
  { value: "wagz", label: "Wagz", field: "platform" },
  { value: "bd", label: "Black + Decker", field: "platform" },
  { value: "petzi", label: "Petzi", field: "platform" },
];

export const mobileAppOS = [
  { value: "android", label: "Android", field: "os" },
  { value: "ios", label: "iOS", field: "os" },
  { value: "fwos", label: "FWOS", field: "os" },
  { value: "web", label: "Web", field: "os" },
];

export const mobileAppUpdateRule = [
  { value: "must", label: "Must", field: "updateRule" },
  { value: "should", label: "Should", field: "updateRule" },
  { value: "optional", label: "Optional", field: "updateRule" },
];

export const mobileAppStatus = [
  { value: "pending", label: "Pending", field: "status" },
  { value: "released", label: "Released", field: "status" },
  { value: "archived", label: "Archived", field: "status" },
];

export const couponTypes = [
  { value: "percent", label: "Percentage", field: "couponType" },
  { value: "flat", label: "Amount in Cents", field: "couponType" },
];

export const couponUseTypes = [
  { value: "single", label: "Single", field: "couponUseType" },
  { value: "multiple", label: "Multiple", field: "couponUseType" },
];

export const couponDurations = [
  { value: "once", label: "Once", field: "couponDuration" },
  { value: "perpetual", label: "Perpetual", field: "couponDuration" },
];

export const leadingCharacters = [
  { value: "124", label: "124 - Annual Sub GC", field: "leading" },
  { value: "385", label: "385 - Reserved", field: "leading" },
  { value: "479", label: "479 - Reserved", field: "leading" },
  { value: "617", label: "617", field: "leading" },
  { value: "764", label: "764", field: "leading" },
  { value: "980", label: "980", field: "leading" },
  { value: "239", label: "239", field: "leading" },
  { value: "571", label: "571", field: "leading" },
  { value: "804", label: "804", field: "leading" },
];

export const couponStatus = [
  { value: "active", label: "Active", field: "status" },
  { value: "used", label: "Used", field: "status" },
  { value: "inactive", label: "Inactive", field: "status" },
];

export const accessLevels = [
  { value: "read_only", label: "Read Only", field: "accessLevel" },
  { value: "write", label: "Write", field: "accessLevel" },
  { value: "admin", label: "Admin", field: "accessLevel" },
];

export const plans = [
  { value: 32, label: "Wagz Connect - Monthly", field: "plans" },
  { value: 33, label: "Wagz Connect - Annual", field: "plans" },
  { value: 35, label: "Small Pet Collar - Monthly", field: "plans" },
  { value: 36, label: "Small Pet Collar - Annual", field: "plans" },
  { value: 40, label: "Multi-pet Annual Subscription", field: "plans" },
];

export const productFamilies = [
  { value: "23", label: "Freedom Collar", field: "productFamily" },
  { value: "24", label: "Small Pet Collar", field: "productFamily" },
];

export const fetchOptions = [
  { value: "yes", label: "Only Recent (default)", field: "recent" },
  { value: "no", label: "Only Abandoned", field: "recent" },
  { value: "", label: "All", field: "recent" },
];

export const typeOptions = [
  { value: "", label: "All", field: "type" },
  { value: "Bowl", label: "Bowl", field: "type" },
  { value: "Collar", label: "Collar", field: "type" },
  { value: "HealthBox", label: "Health Box", field: "type" },
  { value: "Shield", label: "Shield", field: "type" },
];

// Tickets

export const ticketStatus = [
  { value: "open", label: "Open", field: "status" },
  { value: "in_progress", label: "In Progress", field: "status" },
  { value: "resolved", label: "Resolved", field: "status" },
  { value: "closed", label: "Closed", field: "status" },
];

export const ticketDepartment = [
  { value: "all", label: "All", field: "department" },
  { value: "backend", label: "Backend", field: "department" },
  { value: "firmware", label: "Firmware", field: "department" },
  { value: "hardware", label: "Hardware", field: "department" },
  { value: "mobile", label: "Mobile", field: "department" },
  { value: "product", label: "Product - Design - UI/UX", field: "department" },
];

export const ticketCritical = [
  { value: "yes", label: "Yes", field: "critical" },
  { value: "no", label: "No", field: "critical" },
];

export const userOS = [
  { value: "android", label: "Android", field: "userOS" },
  { value: "ios", label: "iOS", field: "userOS" },
  { value: "web", label: "Web", field: "userOS" },
  { value: "unknown", label: "Unknown", field: "userOS" },
];

export const eligibleDevices = [
  { value: "all", label: "All", field: "forDevice" },
  { value: "collar", label: "Collar", field: "forDevice" },
  { value: "door", label: "Door", field: "forDevice" },
  { value: "feeder", label: "Feeder", field: "forDevice" },
  { value: "pendant", label: "Pendant", field: "forDevice" },
  { value: "healthBox", label: "HealthBox", field: "forDevice" },
];

export const defaultIcons = [
  "icn_activity_abduction_normal",
  "icn_activity_alert_normal",
  "icn_activity_allowed_dog_detected_normal",
  "icn_activity_automation_complete",
  "icn_activity_automation_missed",
  "icn_activity_automation_scheduled",
  "icn_activity_bark_normal",
  "icn_activity_battery_charged",
  "icn_activity_battery_low_alert",
  "icn_activity_battery_low_warning",
  "icn_activity_battery_okay_alert",
  "icn_activity_collarlights_normal",
  "icn_activity_correction_normal",
  "icn_activity_exercise_high",
  "icn_activity_exercise_low",
  "icn_activity_forbidden_dog_detected_normal",
  "icn_activity_forbiddendog_normal",
  "icn_activity_general_notification",
  "icn_activity_geofence_alert",
  "icn_activity_geofence_notification",
	"icn_activity_heathbox_general",
	"icn_activity_heathbox_tray_alert",
	"icn_activity_heathbox_tray_notification",
	"icn_activity_heathbox_tray_warning",
  "icn_activity_leashed_normal",
  "icn_activity_locationreport_normal",
	"icn_activity_shield_alert",
	"icn_activity_sleep_high",
	"icn_activity_sleep_low",
  "icn_activity_timereport_normal",
  "icn_activity_waiting_normal",
  "icn_activity_walk_complete"
];

export const messageVariables = [
  "{petName}",
  "{deviceName}",
  "{geofenceName}",
  "{genderPossessive}"
];

export const justificationList = [
  { value: "employee_or_contractor", label: "Employee or Contractor", field: "newFreeUserJustification" },
  { value: "sales_and_marketing", label: "Sales, Marketing, & PR", field: "newFreeUserJustification" },
  { value: "friends_and_family", label: "Friends & Family", field: "newFreeUserJustification" },
  { value: "user_tester", label: "User Tester", field: "newFreeUserJustification" },
  { value: "other", label: "Other (explain in 'Notes')", field: "newFreeUserJustification" },
];