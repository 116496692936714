import moment from "moment";
import { makeCall } from "./client";


export interface IPet {
  breeds: any[] | undefined;
  dateOfBirth: string;
  familyId: number;
  gender: string;
  height: number;
  heightUnits: string;
  id: number;
  licenseExpiration: string;
  licenseNumber: string;
  lifestyle: string;
  name: string;
  rabiesExpiration: string;
  rabiesNumber: string;
  species: string;
  status: string;
  temperaments: string[] | undefined;
  userId: number;
  userRoles: string[] | undefined;
  weight: number;
  weightUnits: string;
  outlierAlerts: "all" | "none" | "critical";
  shieldEnforcement: "on" | "off";
  spokenName: string;
  dailyWaterGoal: number;
}

export const BlankPet: IPet = {
  breeds: [],
  dateOfBirth: "",
  familyId: 0,
  gender: "maleNeutered",
  height: 0,
  heightUnits: "inches",
  id: 0,
  licenseExpiration: "",
  licenseNumber: "",
  lifestyle: "Indoor / Outdoor",
  name: "",
  rabiesExpiration: "",
  rabiesNumber: "",
  species: "dog",
  status: "active",
  temperaments: [],
  userId: 0,
  userRoles: [],
  weight: 0,
  weightUnits: "pounds",
  outlierAlerts: "none",
  shieldEnforcement: "on",
  spokenName: "",
  dailyWaterGoal: 0,
};
export interface IHealthBoxVisit {
  id: number;
  petId: number;
  deviceId: number;
  userId: number;
  startTime: moment.Moment,
  endTime: moment.Moment,
  visitDurationSeconds: number,
  petWeight: number, 
  weightDifferential: number, 
  secondsSincePreviousVisit: number,
  lastUpdated: moment.Moment,
}
export interface IBowlConsumption {
  id: number;
  petId: number;
  deviceId: number;
  userId: number;
  startTime: moment.Moment,
  endTime: moment.Moment,
  consumptionDurationSeconds: number,
  startWeight: number, 
  endWeight: number, 
  weightDifferential: number, 
  configuration: string;
  lastUpdated: moment.Moment,
}

export interface IPetLocation {
  deviceId: number;
  isFirstLocation: boolean;
  lat: number;
  lng: number;
  locationAccuracy: number;
  petId: number;
  posted: string;
  source: string;
}

export interface ICorrectionIntensityInfo {
  enabled: string;
  strength: number;
  warningEnabled: string;
  warningStrength: number;
}
export const BlankIntensity: ICorrectionIntensityInfo = {
  enabled: "",
  strength: 0,
  warningEnabled: "",
  warningStrength: 0,
};

export interface IBowlStatistics {
  userId: number;
  petId: number;
  dailyWaterGoal: number;
  goalPercent: number;
  waterInBowl: number;
  waterConsumed: number;
  lastConsumptionWater: moment.Moment;
  foodInBowl: number;
  foodConsumed: number;
  lastConsumptionFood: moment.Moment;
}
export const BlankBowlStatistics: IBowlStatistics = {
  userId: 0,
  petId: 0,
  dailyWaterGoal: 0,
  goalPercent: 0,
  waterInBowl: 0,
  waterConsumed: 0,
  lastConsumptionWater: moment(),
  foodInBowl: 0,
  foodConsumed: 0,
  lastConsumptionFood: moment(),
};


export class PetsAPI {  

  /**
   * Get all of the pets on the platform
   * @param app 
   * @param options 
   */
  public getAllPets = (app: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/pets`, data);
  }

  /**
   * Get the pet by ID
   * @param app 
   * @param petId 
   * @param options 
   */
  public getPetById = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}`, data);
  }

  /**
   * Gets the pets for a single user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getPetsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/pets`, data);
  };
  
  /**
   * Update some of the fields for the pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public updatePet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/pets/${petId}`, data);
  }

  /**
   * Get the linked devices for a pet by ID
   * @param app 
   * @param petId 
   * @param options 
   */
  public getPetDeviceLinksForPet = (app: string, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/pets/${petId}/links`, data);
  };

  /**
   * Get the linked devices for a pet by ID
   * @param app 
   * @param userId 
   * @param options 
   */
  public getPetDeviceLinksForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/links`, data);
  };

  /**
   * Get the metrics for a pet by ID
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getPetMetrics = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/metrics`, data);
  };

  /**
   * Get the locations for the pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   * @returns 
   */
  public getLocationsFoPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/pets/${petId}/locations`, data);
  };

  /**
   * Get the correction intensity settings for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getCorrectionIntensityPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/settings/intensity`, data);
  }

  /**
   * Get the geofence settings for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getGeofenceSettingsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/geofences/settings`, data);
  }

  /**
   * Get the geofence settings for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getGeofenceInfoForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/geofences`, data);
  }

  /**
   * Get the geofence inset
   * @param app 
   * @param userId 
   * @param fenceId 
   * @param options 
   */
  public getGeofenceInset = (app: string, userId: number, fenceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/geofences/${fenceId}/inset`, data);
  }

  /**
   * 
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public flushUserPetCache = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `/admin/${app}/users/${userId}/pets/cache`, data);
  }

  /**
   * 
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   * @returns 
   */
  public flushPetCache = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `/admin/${app}/users/${userId}/pets/${petId}/cache`, data);
  }

  /**
   * Get the Breeds
   * @param app 
   * @param options 
   * @returns 
   */
  public getBreeds = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/pets/breeds`, data);
  }

  /**
   * Get the breed groups
   * @param app 
   * @param options 
   * @returns 
   */
  public getBreedGroups = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/pets/breeds/groups`, data);
  }

  /**
   * Get the health box visits for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getHealthBoxVisitsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/healthbox/visits`, data);
  }

  /**
   * Get the health box visits for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getHealthBoxVisitsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/devices/${deviceId}/healthbox/visits`, data);
  }

  /**
   * Get the bowl consumption statistics for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getBowlStatisticsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/bowl/statistics`, data);
  }

  /**
   * Get the bowl consumptions for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   */
  public getBowlConsumptionsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${petId}/bowl/consumption`, data);
  }

  /**
   * Get the bowl consumptions for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getBowlConsumptionsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/pets/${deviceId}/bowl/consumption`, data);
  }
  
}
